html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(245, 245, 245);
}
.container {
  margin: 80px auto 0 auto;
}
.nav-container {
  position: relative;
}
.nav-container svg {
  color: #000;
}
.nav-logo {
  position: relative;
  width: fit-content;
  left: 10px;
  text-align: left;
}
.logo {
  max-height: 30px;
}
.logo2 {
  margin-top: 12px;
  margin-left: 20px;
  max-height: 38px;
}
.nav-buttons {
  position: relative;
  width: 100%;
  margin: auto;
  text-align: center;
}
a {
  text-decoration: none;
}
